import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';

export const AdminPanelPage: FC = memo(() => {
  const { t } = useAppTranslation('header');

  return (
    <div className="flex flex-col items-center space-y-2">
      <Link to={AppRoutes.WAREHOUSES}>{t('Warehouses')}</Link>
      <Link to={AppRoutes.USERS}>{t('Users')}</Link>
      <Link to={AppRoutes.CONTRACTS}>{t('Contracts')}</Link>
      <Link to={AppRoutes.RESERVATIONS}>{t('Reservations')}</Link>
      <Link to={AppRoutes.BOXES}>{t('Boxes')}</Link>
      <Link to={AppRoutes.INVOICES}>{t('Invoices')}</Link>
      <Link to={AppRoutes.CREDIT_NOTES}>{t('Credit Notes')}</Link>
      <Link to={AppRoutes.NOTES}>{t('Notes')}</Link>
      <Link to={AppRoutes.GENERAL}>{t('General')}</Link>
      <Link to={AppRoutes.CITIES}>{t('Cities')}</Link>
      <Link to="#">{t('Discounts')}</Link>
      <Link to="#">{t('Price update')}</Link>
      <Link to="#">{t('Insurance')}</Link>
      <Link to="#">{t('Renting stuff')}</Link>
      <Link to="#">{t('History')}</Link>
      <Link to={AppRoutes.TASKS}>{t('Tasks')}</Link>
      <Link to={AppRoutes.BOOKING}>{t('Booking')}</Link>
      <Link to={AppRoutes.GLOBAL_SETTINGS}>{t('Global settings')}</Link>
    </div>
  );
});
