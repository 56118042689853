import React, { FC, memo } from 'react';
import { Trans } from 'react-i18next';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getLoggedUserData, useGetUserByIdQuery } from 'entities/User';
import { Link, useParams } from 'react-router-dom';
import { InfoTag } from 'shared/ui/InfoTag';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useGetCurrencySymbol } from 'app/appState';
import { AppRoutes } from 'app/config/routerConfig/types';
import { AccountDetailsForm } from './AccountDetailsForm';
import { AddressForm } from './AddressForm';
import { LatestActivity } from './LatestActivity';
import { PersonalInvoices } from './PersonalAccounts';

export const ProfilePage: FC = memo(() => {
  const { t } = useAppTranslation('profile');

  const { userId } = useParams();

  const { data } = useGetUserByIdQuery({ userId });

  const loggedUser = useAppSelector(getLoggedUserData);

  const currencySymbol = useGetCurrencySymbol();

  if (!userId) {
    return <div>{t('User not exists')}</div>;
  }

  if (!data) {
    return <div>{t('User not authorized')}</div>;
  }

  const { firstName, lastName, email, phone, dateOfBirth, country, city, street, zip, house, apartment, balance } = data;

  return (
    <div className="pt-7">
      <Trans
        t={t}
        i18nKey="<0>Profile</0> <1>{{firstName}} {{lastName}}</1>"
        components={[
          <span key="0" className="text-2xl font-semibold" />,
          <Link key="1" className="text-2xl font-semibold text-accent" to={`${AppRoutes.USERS}/${userId}/contracts`} />,
        ]}
        values={{ firstName, lastName }}
      />
      <div className="text-accent">
        {t('Balance: {{balance}}', { balance: balance ?? 0 })} {currencySymbol}
      </div>
      <div className="flex space-x-20 mt-10">
        <div className="basis-2/3">
          <InfoTag className="w-full">
            {t('If you want to edit information that is not available to you - contact an administrator')}
          </InfoTag>
          <AccountDetailsForm
            userId={userId}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            dateOfBirth={dateOfBirth}
            balance={balance}
            role={loggedUser?.role}
          />
          <AddressForm userId={userId} house={house} zip={zip} apartment={apartment} street={street} city={city} country={country} />
        </div>
        <div className="flex flex-col basis-1/3 space-y-12">
          <LatestActivity email={email} />
          <PersonalInvoices userId={userId} />
        </div>
      </div>
    </div>
  );
});
