import React, { FC, memo, PropsWithChildren } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useTheme } from 'app/providers/ThemeProvider';

interface TooltipProps extends PropsWithChildren {
  color?: string;
  title: string;
  placement?: TooltipPlacement;
  className?: string;
}

export const Tooltip: FC<TooltipProps> = memo((props) => {
  const { children, title, placement, color, className } = props;

  const theme = useTheme();

  return (
    <AntdTooltip className={className} title={title} color={color || theme.accent} placement={placement}>
      {/* Empty div to fix issue with tooltip child https://github.com/ant-design/ant-design/issues/25214#issuecomment-884640910 */}
      <div />
      {children}
    </AntdTooltip>
  );
});
