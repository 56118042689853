import { Collapse, Divider } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { Invoice, InvoiceItemType, generateInvoiceCreditNotePdfLink, generateInvoicePdfLink } from 'entities/Invoice';
import { User, useAdminRole } from 'entities/User';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { SendInvoiceByEmail } from 'features/SendInvoiceByEmail';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import s from './InvoiceCard.module.scss';
import { PaymentModal } from './PaymentModal';
import { CreditInvoice } from 'features/CreditInvoice';
import { MarkAsPaidInvoice } from 'features/MarkAsPaidInvoice';
import { Deposit, DepositRefundNote } from 'entities/Deposit';

interface InvoiceCardProps {
  user: User;
  invoice: Invoice;
  deposit?: Deposit;
  isClosedContract: boolean;
  defaultOpened?: boolean;
}

export const InvoiceCard: FC<InvoiceCardProps> = memo((props) => {
  const {
    invoice,
    deposit,
    user: { firstName, lastName },
    isClosedContract,
    defaultOpened,
  } = props;

  const { t } = useAppTranslation('contracts');

  const [isOpenedModal, setOpenedModal] = useState(false);

  const { scrollToElement, scrollRef } = useScrollToElement('end', 1000);

  useLayoutEffect(() => {
    defaultOpened && scrollToElement();
  }, [defaultOpened, scrollToElement]);

  const openPaymentModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closePaymentModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const currencySymbol = useGetCurrencySymbol();

  const isAdmin = useAdminRole();

  const isLateChargeInvoice = invoice.invoiceItems.some(({ invoiceItemType }) => invoiceItemType === InvoiceItemType.LATE_CHARGE);

  const isInvoicePaid = invoice.debt === 0;
  const isPaymentNeeded = !isInvoicePaid;
  const paymentsAmount = roundNumber(
    invoice.payments.reduce((acc, payment) => {
      if (payment.creditNote || payment.depositRefundNote) {
        return payment.creditNote
          ? acc + roundNumber(payment.amount - payment.creditNote.creditedAmount)
          : acc + roundNumber(payment.amount - (payment.depositRefundNote as DepositRefundNote).refundAmount);
      }

      return acc + payment.amount;
    }, 0),
  );
  const creditedAmount = roundNumber(invoice.creditNotes.reduce((acc, curr) => acc + curr.creditedAmount, 0));

  const invoiceTotalsTableRows = useMemo(() => {
    const rows = [{ label: t('Total'), value: `${invoice.amount} ${currencySymbol}`, emptyValueSymbol: `0 ${currencySymbol}` }];

    if (paymentsAmount) {
      rows.push({ label: t('Paid amount'), value: `${paymentsAmount} ${currencySymbol}`, emptyValueSymbol: `0 ${currencySymbol}` });
    }

    if (creditedAmount) {
      rows.push({ label: t('Credited amount'), value: `${creditedAmount} ${currencySymbol}`, emptyValueSymbol: `0 ${currencySymbol}` });
    }

    if (invoice.debt) {
      rows.push({ label: t('Debt'), value: `${invoice.debt} ${currencySymbol}`, emptyValueSymbol: `0 ${currencySymbol}` });
    }

    return rows;
  }, [creditedAmount, currencySymbol, invoice.amount, invoice.debt, paymentsAmount, t]);

  const InvoiceContent = useCallback((): JSX.Element => {
    return (
      <div>
        <div>
          {t('To')}: {firstName} {lastName}
        </div>
        <div>
          {t('Mail')}: {invoice.invoiceEmail}
        </div>
        <div>
          {t('Address')}: {invoice.invoiceAddress}
        </div>
        <Divider className="border-primary" />
        {invoice.dateFrom && invoice.dateTo && (
          <>
            <div>{t('Invoicing period')}</div>
            <BulletsTable
              theme="clear"
              rows={[
                { label: t('From'), value: getClientDateFormat(invoice.dateFrom) },
                { label: t('To'), value: getClientDateFormat(invoice.dateTo) },
              ]}
            />
            <Divider className="border-primary" />
          </>
        )}
        <div>
          {invoice.invoiceItems.map((item) => (
            <div key={item.invoiceItemId}>
              {item.invoiceItemType && (
                <div>
                  {t(item.invoiceItemType)} {item.invoiceItemType === InvoiceItemType.BOX ? `#${item?.contractItem?.box?.name}` : null}
                </div>
              )}
              <BulletsTable
                theme="clear"
                rows={[
                  {
                    label: t('Payment'),
                    value: `${roundNumber(item.initialPrice)} ${currencySymbol}`,
                    emptyValueSymbol: `0 ${currencySymbol}`,
                  },
                  {
                    label: t('Discount'),
                    value: `${item.discountPercent}%`,
                    emptyValueSymbol: '0%',
                  },
                  {
                    label: t('VAT'),
                    value: `${item.vatRatePercent}%`,
                    emptyValueSymbol: '0%',
                  },
                ]}
              />
            </div>
          ))}
        </div>
        <Divider className="border-primary" />
        <BulletsTable theme="clear" rows={invoiceTotalsTableRows} />

        <div className="flex justify-between my-3">
          <a href={generateInvoicePdfLink(invoice.invoiceId)} target="_blank" rel="noopener noreferrer" className="font-medium text-accent">
            {t('Download PDF')}
          </a>
          <SendInvoiceByEmail invoiceId={invoice.invoiceId} action={<Button theme="clear">{t('Send by Email')}</Button>} />
        </div>

        {invoice.creditNotes.map((creditNote) => (
          <div key={creditNote.creditNoteId} className="my-3">
            <a
              href={generateInvoiceCreditNotePdfLink(invoice.invoiceId, creditNote.creditNoteId)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-medium text-accent"
            >
              {t('Download Credit Note {{documentNumber}} PDF', { documentNumber: creditNote.documentNumber })}
            </a>
          </div>
        ))}

        <div className="mt-2">
          {isPaymentNeeded && !isClosedContract && (
            <div className="flex flex-col gap-3">
              {isAdmin ? (
                <div className="flex flex-col gap-3">
                  <MarkAsPaidInvoice invoice={invoice} action={<Button theme="secondary">{t('Mark as Paid')}</Button>} />
                  <CreditInvoice invoice={invoice} deposit={deposit} action={<Button theme="primary">{t('Credit Invoice')}</Button>} />
                  <Button theme="danger" onClick={openPaymentModal}>
                    {t('Pay')}
                  </Button>
                </div>
              ) : (
                <Button theme="danger" onClick={openPaymentModal}>
                  {t('Pay')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [
    t,
    firstName,
    lastName,
    invoice,
    invoiceTotalsTableRows,
    isPaymentNeeded,
    isClosedContract,
    isAdmin,
    openPaymentModal,
    currencySymbol,
    deposit,
  ]);

  return (
    <div ref={scrollRef}>
      <PaymentModal invoiceId={invoice.invoiceId} onClose={closePaymentModal} isOpen={isOpenedModal} />
      <Collapse
        className={classNames(s.collapse, {
          [s.needToPay]: !isInvoicePaid,
          [s.paymentAndCreditNote]: creditedAmount && paymentsAmount,
          [s.partialPayment]: paymentsAmount && !isInvoicePaid && !creditedAmount,
          [s.partialCreditNote]: creditedAmount && !isInvoicePaid && !paymentsAmount,
          [s.partialPaymentAndCreditNote]: creditedAmount && paymentsAmount && !isInvoicePaid,
          [s.refundedOrCredited]: isInvoicePaid && creditedAmount,
          [s.completed]: isInvoicePaid && !creditedAmount,
        })}
        defaultActiveKey={defaultOpened ? invoice.invoiceId : undefined}
        items={[
          {
            key: invoice.invoiceId,
            label: (
              <>
                <div className="font-semibold text-lg">
                  {t('Invoice')} {invoice.documentNumber} {isLateChargeInvoice && `(${t('Late charge')})`}
                </div>
                {isAdmin && invoice.description && <div>{invoice.description}</div>}
                {invoice.dueDate && (
                  <div>
                    {t('Pay until')} {getClientDateFormat(invoice.dueDate)}
                  </div>
                )}
              </>
            ),
            children: <InvoiceContent />,
          },
        ]}
        expandIcon={(panelProps) => (
          <CollapseArrow
            className={classNames('ease-linear duration-200', {
              '-rotate-90 fill-primaryLight': panelProps.isActive,
              'rotate-90 fill-secondaryAccent': !panelProps.isActive,
            })}
          />
        )}
        expandIconPosition="end"
      />
    </div>
  );
});
