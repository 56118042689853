import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Invoice, generateInvoicePdfLink, useGetPaginatedInvoicesQuery } from 'entities/Invoice';
import { UnitType } from 'entities/Contract';
import classNames from 'classnames';
import { ExportEntity, ExportModal, getInvoiceColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useGetCurrencySymbol } from 'app/appState';
import { transformInvoicesListFilters } from '../utils/transformInvoicesListFilters';
import { InvoicesListFilter } from './InvoicesListFilter';

export const InvoicesTable: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { openExportModal } = useOpenExportModal();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const goToUserContracts = useCallback(
    (userId: string): void => {
      navigate(`${AppRoutes.USERS}/${userId}/contracts`);
    },
    [navigate],
  );

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<Invoice>> = useMemo(
    () => [
      {
        name: 'downloadInvoice',
        theme: 'primary',
        label: t('Download', { ns: 'common' }),
        onClick: (selectedInvoice) => {
          const invoiceLink = generateInvoicePdfLink(selectedInvoice.invoiceId);

          window.open(invoiceLink);
        },
      },
    ],
    [t],
  );

  const columns = useMemo<ColumnsType<Invoice>>(
    () => [
      {
        title: t('Customer'),
        width: '20%',
        key: 'user',
        render: (_, record) => (
          <div
            className="text-accent font-semibold underline cursor-pointer"
            onClick={() => {
              goToUserContracts(record.user.userId);
            }}
          >
            {`${record.user.firstName} ${record.user.lastName}`}
          </div>
        ),
      },
      {
        title: t('Invoice'),
        key: 'documentNumber',
        width: '15%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div className="text-primary font-normal">
            {t('Invoice')} #{record.documentNumber}
          </div>
        ),
      },
      {
        title: t('Unit type'),
        width: '15%',
        key: 'unitType',
        render: (_, record) => {
          const boxInvoiceItem = record.invoiceItems.find((invoiceItem) => invoiceItem.contractItem?.box);
          const box = boxInvoiceItem?.contractItem?.box;

          const depositInvoiceItem = record.invoiceItems.find(
            (invoiceItem) => invoiceItem.contractItem?.contractUnitType === UnitType.DEPOSIT,
          );

          if (box) {
            return <div className="text-primary font-normal">{box.name}</div>;
          }

          if (!box && depositInvoiceItem) {
            return <div className="text-primary font-normal">{depositInvoiceItem.contractItem?.description}</div>;
          }

          if (!record.contract?.contractId) {
            return <div className="text-primary font-normal">{t('Personal Invoice', { ns: 'common' })}</div>;
          }

          if (record.relatedInvoiceId) {
            return <div className="text-primary font-normal">{t('Late charge')}</div>;
          }

          return <div className="text-primary font-normal">{t('Other', { ns: 'common' })}</div>;
        },
      },

      {
        title: t('Contract number'),
        width: '10%',
        key: 'contract',
        render: (_, record) => (
          <div
            className="text-accent underline cursor-pointer"
            onClick={() => {
              if (record?.contract?.contractId) {
                openContractDetails(record.contract.contractId);
              }
            }}
          >
            #{record.contract?.contractNumber}
          </div>
        ),
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '20%',
        render: (_, record) => <div className="text-primary font-normal">{record.warehouse?.name}</div>,
      },
      {
        title: t('Total amount'),
        key: 'totalAmount',
        width: '20%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.amount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Due date'),
        key: 'dueDate',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.dueDate)}</div>,
      },
      {
        title: t('Created date'),
        key: 'createdAt',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
    ],
    [t, goToUserContracts, openContractDetails, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<Invoice>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformInvoicesListFilters(appliedFilters),
            columns: getInvoiceColumnKeys(t),
            entity: ExportEntity.INVOICE,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, openExportModal, setFiltersOpened, t],
  );

  // TO DO: add Invoice payment status column with color batces (as there are 7 Invoice statuses)
  const highlightRow = (record: Invoice): string => {
    return classNames({
      'bg-successLight': record.debt === 0 && !record.creditNotes.length,
      'bg-warnLight': record.debt === 0 && record.creditNotes.length,
      'bg-gradient-to-r from-successLight to-warnLight': record.debt === 0 && record.creditNotes.length && record.payments.length,
      'bg-gradient-to-r from-successLight via-warnLight to-light': record.debt !== 0 && record.creditNotes.length && record.payments.length,
    });
  };

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Invoices')}</div>
      <ServerTable
        columns={columns}
        rowActions={rowActions}
        fetchData={useGetPaginatedInvoicesQuery}
        rowKey="invoiceId"
        search
        tableActionsPosition="prev"
        rowClassName={highlightRow}
        searchPlaceholder={t('Enter customer name or invoice | contract number')}
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformInvoicesListFilters(appliedFilters)}
      />
      <ContractDetailsDrawer />
      <ExportModal />
      <InvoicesListFilter />
    </div>
  );
});
