import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as EditUserTableIcon } from 'shared/assets/icons/EditUserTableIcon.svg';
import { ReactComponent as EditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as ContractIcon } from 'shared/assets/icons/ContractWhiteIcon.svg';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Lead, useDeleteLeadMutation, useGetPaginatedLeadsQuery, useUpdateLeadMutation } from 'entities/Lead';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { Toggle } from 'shared/ui/Toggle';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { LeadNoteDrawer, leadNoteDrawerActions } from 'features/LeadNoteDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Paragraph } from 'shared/ui/Paragraph';
import { showNotification } from 'app/providers/NotificationsProvider';
import { sendOfferModalActions } from 'widgets/SendOfferModal';
import { AppLanguage } from 'app/config/i18Config/types';
import { stepperModalActions } from 'features/StepperModal';
import { ExportEntity, getLeadColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useTableFilterContext } from 'features/TableFilter';
import { LeadsListFilter } from './LeadsListFilter';
import { transformLeadsListFilters } from '../utils/transformLeadsListFilters';
import { LeadFormModal, leadFormModalActions, LeadFormModalMode } from 'features/LeadFormModal';

export const LeadsTable: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation(['user', 'common']);

  const [selectedLead, setSelectedLead] = useState<Nullable<Lead>>(null);
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const [deleteLead, { isLoading: isDeletionLoading }] = useDeleteLeadMutation();
  const [updateLead] = useUpdateLeadMutation();

  const { openExportModal } = useOpenExportModal();

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const handleOpenLeadNoteDrawer = useCallback(
    (lead: Lead): void => {
      dispatch(leadNoteDrawerActions.setOpenLeadNoteDrawer(lead));
    },
    [dispatch],
  );

  const openSendOfferModal = useCallback((): void => {
    dispatch(sendOfferModalActions.setOpened(true));
  }, [dispatch]);

  const handleSelectOfferModalLead = useCallback(
    (lead: Lead): void => {
      if (lead?.email) {
        dispatch(sendOfferModalActions.setSelectedEmail(lead.email));
        dispatch(sendOfferModalActions.setSelectedLanguage(lead.language as AppLanguage));
        dispatch(stepperModalActions.changeActiveStepIndex(1));
      }

      openSendOfferModal();
    },
    [dispatch, openSendOfferModal],
  );

  const createLead = useCallback((): void => {
    dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: LeadFormModalMode.LEAD_CREATE }));
  }, [dispatch]);

  const editLead = useCallback(
    (lead: Lead): void => {
      dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: LeadFormModalMode.LEAD_EDIT, lead }));
    },
    [dispatch],
  );

  const leadRowActions: Array<ServerTableRowActions<Lead>> = useMemo(
    () => [
      {
        name: 'editLead',
        icon: <EditUserTableIcon />,
        theme: 'clear',
        description: t('Edit lead'),
        onClick: (selectedLead) => {
          editLead(selectedLead);
        },
      },
      {
        name: 'editLeadNote',
        icon: <EditIcon />,
        theme: 'clear',
        description: t('Edit note'),
        onClick: (selectedLead) => {
          handleOpenLeadNoteDrawer(selectedLead);
        },
      },
      // {
      //   name: 'leadProfile',
      //   icon: <ProfileIcon />,
      //   theme: 'clear',
      //   onClick: (selectedUser) => {
      //     console.log(selectedUser);
      //   },
      // },
      {
        name: 'deleteLead',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete lead'),
        onClick: (selectedLead: Lead) => {
          setSelectedLead(selectedLead);
          openConfirmModal();
        },
      },
      {
        name: 'sendOffer',
        theme: 'primary',
        label: t('Send offer'),
        icon: <ContractIcon />,
        iconPosition: 'prev',
        onClick: (selectedUser) => {
          handleSelectOfferModalLead(selectedUser);
        },
      },
    ],
    [editLead, handleOpenLeadNoteDrawer, handleSelectOfferModalLead, openConfirmModal, t],
  );

  const handleDeleteLead = useCallback(async (): Promise<void> => {
    try {
      if (selectedLead) {
        await deleteLead(selectedLead.leadId).unwrap();
        showNotification('info', t('Success'), t('Lead deleted successfully'));
      }
    } catch (error) {
      showNotification('error', t('Error'), t('Error when deleting lead'));
      console.log('error', error);
    } finally {
      closeConfirmModal();
    }
  }, [selectedLead, deleteLead, t, closeConfirmModal]);

  const handleToggleLeadLeft = useCallback(
    async (lead: Lead) => {
      try {
        await updateLead({ leadId: lead.leadId, isLeft: !lead.isLeft }).unwrap();
        showNotification('info', t('Success'), t('Lead left changed successfully'));
      } catch (error) {
        showNotification('error', t('Error'), t('Error when changing lead left'));
        console.log('error', error);
      }
    },
    [t, updateLead],
  );

  const leadColumns = useMemo<ColumnsType<Lead>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => {
          const isNewLead = dayjs().diff(dayjs(record.createdAt), 'day') < 7 && !record?.contractId;

          const commonBadgeClassName = 'absolute top-0 left-0 text-sm text-white font-normal rounded-br-lg bg-success px-5 py-0.5';

          const badgeClassName = classNames(commonBadgeClassName, {
            '!bg-accent': record.isContractPaid && !record.isLeft,
            '!bg-warn': record.contractId && !record.isLeft && !record.isContractPaid && !record.contractEndDate,
            '!bg-primary': record.isLeft,
            '!bg-primaryLight': record.reservationId && !record.isLeft && !record.isContractPaid && !record.contractEndDate,
            '!bg-error': record.contractId && record.contractEndDate && !record.isLeft,
          });

          let badgeTitle = t('Active requests');

          if (record.isContractPaid) {
            badgeTitle = t('Paid');
          }

          if (record.contractId && !record.isContractPaid && !record.isLeft) {
            badgeTitle = t('Not paid');
          }

          if (record.reservationId && !record.contractId && !record.isLeft) {
            badgeTitle = t('Reservation');
          }

          if (record.contractId && record.contractEndDate && !record.isLeft) {
            badgeTitle = t('End');
          }

          if (record.isLeft) {
            badgeTitle = t('Left');
          }

          return (
            <div>
              {record.firstName ? (
                <span className="text-primary font-semibold">
                  {record.firstName} {record.lastName || ''}
                </span>
              ) : (
                'N/A'
              )}
              {isNewLead && <sup className="text-sm text-success p-1">{t('New')}</sup>}
              <div className={badgeClassName}>{badgeTitle}</div>
            </div>
          );
        },
      },
      {
        title: t('Email/Phone'),
        key: 'email',
        render: (_, record) => (
          <div>
            {record.email && <div className="text-primary font-normal">{record.email}</div>}
            {record.phone && <div className="text-primary font-normal">{record.phone}</div>}
          </div>
        ),
      },
      {
        title: t('Warehouse'),
        key: 'warehouseId',
        render: (_, record) => (
          <>
            {record.warehouse && (
              <div className="text-primary">
                <div className="font-normal">{record.warehouse.name}</div>
                <div className="font-normal">{record.warehouse.address}</div>
              </div>
            )}
          </>
        ),
      },
      {
        title: t('Date'),
        key: 'createdAt',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className=" text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
      {
        title: t('Box size'),
        key: 'square',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <>
            {record.square && (
              <div>
                {record.square}m<sup>2</sup>
              </div>
            )}
          </>
        ),
      },
      {
        title: t('Left'),
        key: 'isLeft',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div>
            <Toggle
              checked={!record.isLeft}
              onChange={async () => {
                await handleToggleLeadLeft(record);
              }}
            />
            <div className="text-xs text-primaryLight mt-2">{t(!record.isLeft ? 'Relevant' : 'Irrelevant')}</div>
          </div>
        ),
      },
      {
        title: t('Note'),
        key: 'note',
        render: (_, record) => {
          const paragraphs = record.note?.split('\n');

          const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
            <p key={index} className="font-normal !mb-0">
              {paragraph}
            </p>
          ));

          return <Paragraph rows={3}>{paragraphElements}</Paragraph>;
        },
      },
    ],
    [handleToggleLeadLeft, t],
  );

  const leadTableActions: Array<ServerTableActions<Lead>> = useMemo(
    () => [
      {
        name: t('Send offer'),
        theme: 'primary',
        icon: <ContractIcon />,
        iconPosition: 'prev',
        onClick: openSendOfferModal,
      },
      {
        name: t('Create Lead'),
        theme: 'secondary',
        icon: <PlusIcon className="[&>path]:stroke-primary" />,
        iconPosition: 'prev',
        onClick: createLead,
      },
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformLeadsListFilters(appliedFilters),
            columns: getLeadColumnKeys(t),
            entity: ExportEntity.LEAD,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, createLead, openExportModal, setFiltersOpened, openSendOfferModal, t],
  );

  return (
    <div className="space-y-4">
      <ServerTable
        columns={leadColumns}
        rowActions={leadRowActions}
        tableActions={leadTableActions}
        tableActionsPosition="prev"
        fetchData={useGetPaginatedLeadsQuery}
        defaultFilters={transformLeadsListFilters(appliedFilters)}
        rowKey="leadId"
        search
        searchPlaceholder={t('Enter lead`s name, phone or email')}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        rowActionsLimit={3}
      />
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Delete lead')}
        description={t('Are you sure you want to delete this lead?')}
        isLoading={isDeletionLoading}
        onOk={handleDeleteLead}
        onCancel={closeConfirmModal}
      />
      <LeadFormModal />
      <LeadNoteDrawer />
      <LeadsListFilter />
    </div>
  );
});
