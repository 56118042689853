import { api } from 'app/config/apiConfig';

export const sendInvoiceByEmailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendInvoiceByEmail: builder.mutation<void, string>({
      query: (invoiceId) => ({
        url: '/billing-documents/invoices/send-invoice',
        method: 'POST',
        body: { invoiceId },
      }),
    }),
  }),
});

export const { useSendInvoiceByEmailMutation } = sendInvoiceByEmailApi;
